<template>
  <div class="d-flex color-picker p-relative" ref="colorpicker">
    <div class="color-picker-container pointer" :style="'background-color: ' + colorValue">
      <chrome-picker :value="colors" @input="updateFromPicker" v-if="displayPicker" class="cus-color-picker" />
    </div>
    <input type="text" class="input" v-model="colorValue" @focus="showPicker()" @input="updateFromInput" />
  </div>
</template>

<script>
export default {
    name: 'ColorPicker',
    props: {
        color: String
    },
    data() {
        return {
            colors: {
                hex: '#000000',
            },
            colorValue: '',
            displayPicker: false
        }
    },
    methods: {
        setColor(color) {
            color = color || '#000000';
            this.updateColors(color);
            this.colorValue = color;
        },
        updateColors(color) {
            if (color.slice(0, 1) == '#') {
                this.colors = {
                    hex: color
                };
            }
            else if (color.slice(0, 4) == 'rgba') {
                var rgba = color.replace(/^rgba?\(|\s+|\)$/g, '').split(','),
                    hex = '#' + ((1 << 24) + (parseInt(rgba[0]) << 16) + (parseInt(rgba[1]) << 8) + parseInt(rgba[2])).toString(16).slice(1);
                this.colors = {
                    hex: hex,
                    a: rgba[3],
                }
            }
        },
        showPicker() {
            document.addEventListener('click', this.documentClick);
            this.displayPicker = true;
        },
        hidePicker() {
            document.removeEventListener('click', this.documentClick);
            this.displayPicker = false;
        },
        togglePicker() {
            this.displayPicker ? this.hidePicker() : this.showPicker();
        },
        updateFromInput() {
            this.updateColors(this.colorValue);
        },
        updateFromPicker(color) {
            this.colors = color;
            if (color.rgba.a == 1) {
                this.colorValue = color.hex;
            }
            else {
                this.colorValue = 'rgba(' + color.rgba.r + ', ' + color.rgba.g + ', ' + color.rgba.b + ', ' + color.rgba.a + ')';
            }
        },
        documentClick(e) {
            let el = this.$refs.colorpicker;
            let target = e.target;
            if (el !== target && !el.contains(target)) {
                this.hidePicker()
            }
        }
    },
    watch: {
        colorValue(val) {
            if (val) {
                this.updateColors(val);
                this.$emit('input', val);
                //document.body.style.background = val;
            }
        },
        color(val) {
            this.setColor(val);
        }
    },
    mounted() {
        this.setColor(this.color);
    }
}
</script>

<style lang="scss" scoped>
.color-picker {
  .color-picker-container {
    width: 100px;
  }
  .cus-color-picker {
    position: absolute;
    top: 38px;
    z-index: 9;
  }
}
</style>