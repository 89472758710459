<template>
  <main class="color-primary h-100 page_bg_grey blank-aside-js" :class = "(isOpenAside=='true')?'blank-aside':''">
    <p class="main-title weight-700">
      {{ $t('Suppliers') }}
    </p>
    <section class="content-margin">
      <div class="row">
        <div class="col-sm-3 mb-10">
          <label>{{ $t('Select a category') }}</label>
          <vue-select 
            :options="supplierCategory.options" 
            class="input cus-select"
            v-model="supplierCategory.selected"
            @input="handleChangeCategory"
          ></vue-select>
        </div>
        <div class="col-sm-3 mb-10" v-if="supplierCategory.selected == 'home'">
          <label>{{ $t('Show on') }}</label>
          <vue-select 
            :options="showOn.options" 
            class="input cus-select"
            v-model="showOn.selected"
          ></vue-select>
        </div>
      </div>
      <!-- order part -->
      <div v-if="supplierCardsData.length">
        <p class="fs14">{{ $t("Activated Suppliers") }}</p>
        <draggable class="d-flex justify-content-center flex-wrap top-supplier-block" v-model="supplierCardsData" @start="drag=true" @end="drag=false">
          <div v-for="(card, i) in supplierCardsData" :key="i" class="p-relative" v-if="card.status == 1 && card[showOn.selected.code]">
            <v-btn
              fab
              dark
              x-small
              color="primary"
              class="supplier-edit_btn"
              @click="openModalEditSupplier(card.id)"
            >
              <v-icon dark>
                mdi-pencil
              </v-icon>
            </v-btn>
            <SupplierCard
              class="cursor-move"
              :text="card.name"
              :logo="card.image"
              :bgColor="card.logo_background_color"
              :titleBgColor="card.logo_title_background"
              :fontColor="card.font_color"
            >
            </SupplierCard>
          </div>
        </draggable>
        <div class="d-flex justify-content-center mt-20">
          <v-btn
            color="light"
            class="text-capitalize mx-10"
            @click="cancelOrder()"
          >
            {{ $t("Reset") }}	
          </v-btn>
          <v-btn
            color="primary"
            class="text-capitalize"
            @click="saveOrder()"
          >
            {{ $t("Save Order") }}	
          </v-btn>
        </div>

        <div v-if="supplierCategory.selected == 'home'">
          <v-divider></v-divider>
          <p class="fs14">{{ $t("Unshown Suppliers") }}</p>
          <div class="d-flex justify-content-center flex-wrap top-supplier-block">
            <div v-for="(card, i) in supplierCardsData" :key="i" class="p-relative" v-if="card.status == 1 && !card[showOn.selected.code]">
              <v-btn
                fab
                dark
                x-small
                color="primary"
                class="supplier-edit_btn"
                @click="openModalEditSupplier(card.id)"
              >
                <v-icon dark>
                  mdi-pencil
                </v-icon>
              </v-btn>
              <SupplierCard
                :text="card.name"
                :logo="card.image"
                :bgColor="card.logo_background_color"
                :titleBgColor="card.logo_title_background"
                :fontColor="card.font_color"
              >
              </SupplierCard>
            </div>
          </div>
        </div>
        
        <v-divider></v-divider>
        <p class="fs14">{{ $t("Deactivated Suppliers") }}</p>
        <div class="d-flex justify-content-center flex-wrap top-supplier-block">
          <div v-for="(card, i) in supplierCardsData" :key="i" class="p-relative" v-if="card.status == 2">
            <v-btn
              fab
              dark
              x-small
              color="primary"
              class="supplier-edit_btn"
              @click="openModalEditSupplier(card.id)"
            >
              <v-icon dark>
                mdi-pencil
              </v-icon>
            </v-btn>
            <SupplierCard
              :text="card.name"
              :logo="card.image"
              :bgColor="card.logo_background_color"
              :titleBgColor="card.logo_title_background"
              :fontColor="card.font_color"
            >
            </SupplierCard>
          </div>
        </div>
      </div>
    </section>

    <ModalEditSupplier ref="modalEditSupplierRef" @submit="handleSubmitSupplier"></ModalEditSupplier>
    <loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
  </main>    
</template>

<script>
import { mapState, mapActions } from "vuex";
import SupplierCard from '@/components/endUser/SupplierCard';
import ModalEditSupplier from './ModalEditSupplier';

export default {
  name: 'CPSuppliers',
  components: {
    SupplierCard,
    ModalEditSupplier
  },
  data() {
    return {
      supplierCardsData: [],
      supplierCategory: {
        options: [],
        selected: ''
      },
      showOn: {
        options: [
          {
            code: 'show_destop',
            label: this.$t('Desktop')
          },
          {
            code: 'show_tablet',
            label: this.$t('Tablet')
          },
          {
            code: 'show_mobile',
            label: this.$t('Mobile')
          },
        ],
        selected: {
          code: 'show_destop',
          label: this.$t('Desktop')
        }
      },
    }
  },
  computed: {
    ...mapState({
      loading: (state) => state.supplier.loading,
      supplier_items: state => state.supplier.supplier_items,
    }),
    storageSupplierCategoryId() {
      return localStorage.getItem("cpSupplierCategory");
    },
  },
  async created () {
    await this.getAllSupplierItems()
    if (this.supplier_items) {
      for (let supplierType in this.supplier_items) {
        this.supplierCategory.options.push(supplierType);
      }
      if (this.storageSupplierCategoryId) {
        this.supplierCategory.selected = this.storageSupplierCategoryId;
      } else {
        this.supplierCategory.selected = this.supplierCategory.options[0];
      }
      this.supplierCardsData = this.supplier_items[this.supplierCategory.selected].sort((a,b) => a.customer_home_order - b.customer_home_order);
    }
  },
  methods: {
    ...mapActions('supplier', {
      getAllSupplierItems: 'getAllSupplierItems',
      updateOrder: 'updateOrder',
    }),
    handleChangeCategory() {
      const supplierType = this.supplierCategory.selected;
      const supplierTypeItems = this.supplier_items[supplierType];
      this.supplierCardsData = supplierTypeItems.sort((a,b) => a.customer_home_order - b.customer_home_order);
      localStorage.setItem("cpSupplierCategory", supplierType);
    },
    cancelOrder() {
      this.supplierCardsData = this.supplier_items[this.supplierCategory.selected].sort((a,b) => a.customer_home_order - b.customer_home_order);
    },
    saveOrder() {
      let params = [];
      this.supplierCardsData.forEach((el, index) => {
        params.push({
          id: el.id,
          order: index + 1
        })
      });
      this.updateOrder({params}).then(res => {
        console.log('front res:', res);
      });
    },
    openModalEditSupplier(id) {
      const selectedCategory = this.supplierCategory.selected;
      this.$refs.modalEditSupplierRef.openModal(id, selectedCategory);
    },
    async handleSubmitSupplier() {
      await this.getAllSupplierItems();
      this.supplierCardsData = this.supplier_items[this.supplierCategory.selected].sort((a,b) => a.customer_home_order - b.customer_home_order);
    },
   
  }
}
</script>

<style lang="scss" scoped>
  .supplier-edit_btn {
    position: absolute;
    right: 0;
    top: -3px;
    z-index: 10;
  }
</style>