<template>
  <modal :width="900" :adaptive="true" class="CEModal modal-scroll-bar" name="ModalEditSupplier">
    <button class="button cus-close-modal_btn" @click="closeModal()">
      <img src="/assets/img/close.svg" alt />
    </button>
    <div class="font-weight-bold fs18 px-12 py-9">
      {{ $t("Edit Supplier") }}
    </div>

    <section class="p-10 grey lighten-3">
      <div class="row">
        <div class="col-sm-6 col-md-4 validation-form-group" :class="{
          'form-group--error': $v.suppliers_data.name.$error,
        }">
          <p class="fs14 mb-0">{{ $t("Name") }} <span class="red--text">*</span></p>
          <input type="text" class="input" v-model="suppliers_data.name" />
          <div class="color-red1 fs12 valide-error-msg" v-if="$v.suppliers_data.name.$error">
            {{ $t('Invalid value') }}
          </div>
        </div>
        <div class="col-sm-6 col-md-4">
          <p class="fs14 mb-0">{{ $t("Contact") }}</p>
          <input type="text" class="input" v-model="suppliers_data.contact_name" />
        </div>
        <div class="col-sm-6 col-md-4">
          <p class="fs14 mb-0">{{ $t("Phone") }}</p>
          <input type="text" class="input" v-model="suppliers_data.contact_phone_number" />
        </div>
        <div class="col-sm-6 col-md-4">
          <p class="fs14 mb-0">{{ $t("Address") }}</p>
          <input type="text" class="input" v-model="suppliers_data.address" />
        </div>
        <div class="col-sm-6 col-md-4">
          <p class="fs14 mb-0">{{ $t("Supplier type") }}</p>
          <input type="text" class="input" v-model="suppliers_data.type" />
        </div>
        <div class="col-sm-6 col-md-4">
          <p class="fs14 mb-0">{{ $t("Maximum profit %") }}</p>
          <input type="text" class="input" v-model="suppliers_data.max_percentage_profit" />
        </div>
        <div class="col-sm-6 col-md-4">
          <p class="fs14 mb-0">{{ $t("Minimum profit %") }}</p>
          <input type="text" class="input" v-model="suppliers_data.min_percentage_profit" />
        </div>
        <div class="col-sm-6 col-md-4">
          <p class="fs14 mb-0">{{ $t("Limit automatic payment on credit") }}</p>
          <input type="text" class="input" v-model="suppliers_data.max_payment" />
        </div>
        <div class="col-sm-6 col-md-4">
          <p class="fs14 mb-0">{{ $t("Provider Type") }}</p>
          <select class="input" v-model="suppliers_data.provider_type">
            <option v-for="item in suppliers_data.provider_type_api" :value="item"
              :selected="suppliers_data.provider_type === item">{{ item }}</option>
          </select>
        </div>
        <div v-if="selectedCategory == 'bills'" class="col-sm-6 col-md-4 validation-form-group" :class="{
          'form-group--error': $v.suppliers_data.credit_card_id.$error,
        }">
          <p class="fs14 mb-0">{{ $t("Credit card to provide") }} <span class="red--text">*</span></p>
          <input type="text" class="input" v-model="suppliers_data.credit_card_id" />
          <div class="color-red1 fs12 valide-error-msg" v-if="$v.suppliers_data.credit_card_id.$error">
            {{ $t('Invalid value') }}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6 col-md-4 py-0">
          <v-checkbox color="#0D3856" v-model="status" :label="$t('Active')"></v-checkbox>
        </div>
        <div class="col-sm-6 col-md-4 py-0">
          <v-checkbox color="#0D3856" v-model="suppliers_data.cancel_option"
            :label="$t('Authorized for revocation')"></v-checkbox>
        </div>
        <div class="col-sm-6 col-md-4 py-0">
          <v-checkbox color="#0D3856" v-model="suppliers_data.default_creation"
            :label="$t('Default in setting up a reseller')"></v-checkbox>
        </div>
      </div>
      <div class="row mt-0" v-if="selectedCategory == 'home'">
        <div class="col-sm-6 col-md-4 py-0">
          <v-checkbox color="#0D3856" v-model="suppliers_data.show_destop" :label="$t('Shows on desktop')"></v-checkbox>
        </div>
        <div class="col-sm-6 col-md-4 py-0">
          <v-checkbox color="#0D3856" v-model="suppliers_data.show_tablet" :label="$t('Shows on tablet')"></v-checkbox>
        </div>
        <div class="col-sm-6 col-md-4 py-0">
          <v-checkbox color="#0D3856" v-model="suppliers_data.show_mobile" :label="$t('Shows on mobile')"></v-checkbox>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6">
          <div class="row">
            <div class="col-sm-6">
              <label class="fs14">{{ $t('Supplier font') }}</label>
              <ColorPicker :color="suppliers_data.font_color" v-model="suppliers_data.font_color" />
            </div>
            <div class="col-sm-6">
              <label class="fs14">{{ $t('Supplier light font') }}</label>
              <ColorPicker :color="suppliers_data.light_logo_font_color" v-model="suppliers_data.light_logo_font_color" />
            </div>
            <div class="col-sm-6">
              <label class="fs14">{{ $t('Supplier font background') }}</label>
              <ColorPicker :color="suppliers_data.logo_title_background" v-model="suppliers_data.logo_title_background" />
            </div>
            <div class="col-sm-6">
              <label class="fs14">{{ $t('Supplier light font background') }}</label>
              <ColorPicker :color="suppliers_data.light_logo_title_background"
                v-model="suppliers_data.light_logo_title_background" />
            </div>
            <div class="col-sm-6">
              <label class="fs14">{{ $t('Supplier background') }}</label>
              <ColorPicker :color="suppliers_data.logo_background_color" v-model="suppliers_data.logo_background_color" />
            </div>
            <div class="col-sm-6">
              <label class="fs14">{{ $t('Supplier light background') }}</label>
              <ColorPicker :color="suppliers_data.light_logo_background_color"
                v-model="suppliers_data.light_logo_background_color" />
            </div>
            <div class="col-sm-6">
              <label class="fs14">{{ $t('Product font') }}</label>
              <ColorPicker :color="suppliers_data.product_font_color" v-model="suppliers_data.product_font_color" />
            </div>
            <div class="col-sm-6">
              <label class="fs14">{{ $t('Product background') }}</label>
              <ColorPicker :color="suppliers_data.product_card_background_color"
                v-model="suppliers_data.product_card_background_color" />
            </div>
            <div class="col-sm-6">
              <label class="fs14">{{ $t('Selected product font') }}</label>
              <ColorPicker :color="suppliers_data.chosen_product_font_color"
                v-model="suppliers_data.chosen_product_font_color" />
            </div>
            <div class="col-sm-6">
              <label class="fs14">{{ $t('Selected product background') }}</label>
              <ColorPicker :color="suppliers_data.chosen_card_background_color"
                v-model="suppliers_data.chosen_card_background_color" />
            </div>
            <div class="col-sm-6">
              <label class="fs14">{{ $t('Favorite star') }}</label>
              <ColorPicker :color="suppliers_data.favorite_star_color" v-model="suppliers_data.favorite_star_color" />
            </div>
            <div class="col-sm-6">
              <label class="fs14">{{ $t('Side logo background') }}</label>
              <ColorPicker :color="suppliers_data.product_side_logo_background_color"
                v-model="suppliers_data.product_side_logo_background_color" />
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <label class="fs14">{{ $t('Supplier logo') }} / {{ $t('Category') }}</label>
          <div class="form-item upload-block p-relative">
            <div class="upload-setting">
              <div v-for="(imageToUpload, index) in imagesToUpload" :key="imageToUpload.key"
                class="setting-btn bright-btn" :class="{ 'active': selectedImageToUpload === imageToUpload.key }"
                @click="selectedImageToUpload = imageToUpload.key">
                {{ imageToUpload.title }}
              </div>
            </div>
            <div v-for="(imageToUpload, index) in imagesToUpload" :key="imageToUpload.key"
              v-show="selectedImageToUpload === imageToUpload.key" class="upload-part bdbr-2"
              :style="{ backgroundColor: imageToUpload.background_color_key in suppliers_data && suppliers_data[imageToUpload.background_color_key] }">
              <input class="tour-passport__file w-100" type="file" @change="handleImage" accept="image/*"
                :name="imageToUpload.key" multiple="multiple" />
              <img v-show="suppliers_data && suppliers_data[`${imageToUpload.key}_preview`]"
                :src="suppliers_data[`${imageToUpload.key}_preview`]" class="preview-img" />
              <img v-show="!suppliers_data || !suppliers_data[`${imageToUpload.key}_preview`]"
                :src="suppliers_data && suppliers_data[imageToUpload.key] ? suppliers_data[imageToUpload.key] : '/assets/img/icons/upload.png'"
                class="upload-icon" />
              <div :style="{
                background: imageToUpload.title_background_color_key in suppliers_data && suppliers_data[imageToUpload.title_background_color_key],
                color: imageToUpload.font_color_key in suppliers_data && suppliers_data[imageToUpload.font_color_key],
                padding: '10px 0',
                textAlign: 'center',
                position: 'absolute',
                bottom: '-45px',
                width: '100%'
              }">
                {{ suppliers_data.name }}
              </div>
            </div>
          </div>

          <!-- Only display for games -->
          <div class="w-100 mt-25" v-if="suppliers_data.type == 6">
            <div class="d-flex mt-5">
              <div class="d-flex align-items-center">
                <v-checkbox color="#0D3856" class="cus-checkbox" v-model="suppliers_data.add_vat"></v-checkbox>
                <label class="px-5 fs14">{{ $t('Add Vat') }}</label>
              </div>
              <div class="w-50 form-div px-7 mb-10">
                <label class="fs14">{{ $t('Percentage Profit') }}</label>
                <input type="text" class="input" v-model="suppliers_data.percentage_profit" />
              </div>
            </div>
            <div class="w-100 form-div mb-10">
              <label class="fs14">{{ $t('Products source') }}</label>
              <input type="text" class="input" v-model="suppliers_data.products_source" />
            </div>
          </div>

          <!-- Only display for weCom -->
          <div class="w-100 mt-25" v-if="suppliers_data.id == 1100">
            <label for="" class="font-weight-bold">{{ $t('Import New Numbers') }}</label>
            <input type="file" @change="handleImportMobileNumbersFile" />
          </div>
        </div>
      </div>
    </section>
    <div class="py-10 px-20 fs14">
      <div class="d-flex justify-content-end">
        <v-btn class="text-none" @click="closeModal()">
          {{ $t("Cancel") }}
        </v-btn>
        <v-btn color="orange" class="text-none mx-10 white--text" @click="handleSyncSupplierProduct()">
          {{ $t("Sync now") }}
        </v-btn>
        <v-btn color="primary" class="text-none" @click="update()">
          {{ $t("Save") }}
        </v-btn>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import ColorPicker from '@/components/ColorPicker'

import readXlsxFile from 'read-excel-file';

export default {
  name: "ModalEditSupplier",
  mixins: [validationMixin],
  validations: {
    suppliers_data: {
      name: {
        required
      },
      credit_card_id: {
        required
      }
    },
  },
  components: {
    ColorPicker,
  },
  data() {
    return {
      supplierID: '',
      selectedCategory: '',
      status: false,

      imagesToUpload: [
        {
          title: this.$t('Dark'),
          key: 'image',
          background_color_key: 'logo_background_color',
          font_color_key: 'font_color',
          title_background_color_key: 'logo_title_background',
        },
        {
          title: this.$t('Bright'),
          key: 'light_logo',
          background_color_key: 'light_logo_background_color',
          font_color_key: 'light_logo_font_color',
          title_background_color_key: 'light_logo_title_background',
        },
      ],
      selectedImageToUpload: 'image',
      suppliers_data: {},
      weComMobileNumbers: []
    }
  },
  computed: {
    ...mapState({
      loading: (state) => state.supplier.loading,
      supplier_items: state => state.supplier.supplier_items,
      supplierData: state => state.supplier.supplierData,
    }),
    showSyncButton() {
      return this.suppliers_data && this.suppliers_data.id
        ? [1100, 1101].includes(this.suppliers_data.id) : false;
    },
  },
  methods: {
    ...mapActions('supplier', {
      getSupplierDataById: 'getSupplierDataById',
      updateSupplierDataById: 'updateSupplierDataById',
      syncSupplierProducts: 'syncSupplierProducts',
    }),

    closeModal() {
      this.$v.$reset();
      this.$modal.hide("ModalEditSupplier");
    },
    openModal(id, selectedCategory) {
      this.$modal.show("ModalEditSupplier");

      this.suppliers_data = {};
      localStorage.setItem("cpSuppliersId", id);
      this.supplierID = id;
      this.selectedCategory = selectedCategory;

      this.getSupplierDataById({
        supplier_id: id
      }).then(() => {
        this.suppliers_data = { ...this.supplierData };
        this.setStatus();
      });
    },

    handleSyncSupplierProduct() {
      if (!this.suppliers_data || !this.suppliers_data.id) {
        return false;
      }

      this.syncSupplierProducts({ supplier_id: this.suppliers_data.id });
    },
    handleImage(e) {
      if (e.target.files.length > 0) {
        const imageKey = e.target.name;
        const imageFile = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(imageFile);
        reader.onload = (e) => {
          this.suppliers_data[`${imageKey}_file`] = imageFile;
          this.suppliers_data[`${imageKey}_preview`] = e.target.result;
          this.suppliers_data = { ...this.suppliers_data };
        };
      }
    },
    update() {
      if (this.selectedCategory != 'bills') {
        this.suppliers_data.credit_card_id = 1;
      }
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }

      let formData = new FormData();

      for (var key in this.suppliers_data) {
        if (key == 'image' || key == 'light_logo' || key == 'status')
          continue;
        formData.append(key, this.suppliers_data[key]);
      }

      formData.append('status', this.status ? 1 : 2);

      for (let imageToUpload of this.imagesToUpload) {
        if (`${imageToUpload.key}_file` in this.suppliers_data
          && this.suppliers_data[`${imageToUpload.key}_file`]) {
          formData.append(imageToUpload.key, this.suppliers_data[`${imageToUpload.key}_file`]);
        }
      }

      if (this.suppliers_data.id == 1100) {
        formData.append('weComMobileNumbers', this.weComMobileNumbers);
      }

      this.updateSupplierDataById({
        supplier_id: this.supplierID,
        supplier_data: formData
      }).then(async () => {
        this.$emit('submit');
        this.closeModal();
      });
    },
    setStatus() {
      this.status = this.suppliers_data && (this.suppliers_data.status === 1 || this.suppliers_data.status === '1');
    },
    handleImportMobileNumbersFile(event) {
      let xlsxfile = event.target.files ? event.target.files[0] : null;
      readXlsxFile(xlsxfile).then((rows) => {
        let mobileNumbers = [];
        rows.forEach(el => {
          if (el[0]) {
            mobileNumbers.push(el[0]);
          }
        });
        console.log('mobileNumbers', mobileNumbers);
        this.weComMobileNumbers = mobileNumbers;
      })
    }
  }
}
</script>

<style lang="scss">
.upload-block {
  .upload-setting {
    position: absolute;
    top: -18px;
    left: 0;
    display: flex;
    cursor: pointer;

    .setting-btn {
      height: 16px;
      font-size: 12px;
      padding: 0 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      color: #002036;

      &.active {
        background-color: #002036;
        color: #fff;
      }

      &.bright-btn {
        margin: 0 5px;
      }
    }
  }

  .upload-part {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 300px;

    .upload-icon {
      height: 70%;
      max-width: 80%;
    }

    .preview-img {
      object-fit: contain;
      height: 70%;
      max-width: 80%;
    }
  }

  .tour-passport__file {
    height: 300px;
  }
}

.ltr-type {
  .upload-block {
    .upload-setting {
      left: unset;
      right: 0;
    }
  }
}
</style>